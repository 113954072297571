import "./css/office.css";
import quoteM from "../../images/quoteIcon.jpg";
import LFilter from "../../images/filter.png";
import CManager from "../../images/1478890.png";
import LManager from "../../images/1478890.png";
import reporting from "../../images/report-vector-icon-png_295013.jpg";
import products from "../../images/productManager.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InvalidAccess from "./InvalidAccess";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Dashboard() {
  const Navigate = useNavigate();
  const currentPage = useLocation().pathname;
  useLocation().pathname = "customers";
  let username = localStorage.getItem("user");
  let ryts = localStorage.getItem("user_rights");
  if (
    localStorage.getItem("user") == 20000 ||
    localStorage.getItem("user") == ""
  ) {
    return <InvalidAccess />;
  } else if (username === null) {
    return Navigate("../");
  } else {
    return (
      <div>
        <div id="welcome">
          Welcome Back <br />
          <strong>
            {localStorage.getItem("user_name")}{" "}
            <small>({localStorage.getItem("business")})</small>
          </strong>
        </div>
        <div id="logout">
          <small>
            <Link to="../logout"> Logout </Link>
          </small>
        </div>{" "}
        <div id="logout">
          <small>{/* <Link to="../profile"> Update profile </Link> */}</small>
        </div>
        <div className="officeNav">
          {ryts != 3 ? <>
          {/* <Link className="Lead Manager">
            <img src={CManager} /> <br />
            Lead Manager
          </Link> */}
          <Link className="nav-link" to="../lead" >
            <img src={LFilter} /> <br />
            Lead Manager
          </Link>
          <Link className="nav-link" to="../customers" >
            <img src={CManager} /> <br />
            Add/Edit Customer(s)
          </Link>
          <Link className="nav-link" to="../quotes" >
            <img src={quoteM} /><br />
            Quote Manager
          </Link></>
           : "" }
            
          <Link
            className={
              currentPage === "/reports" ? "nav-link active" : "nav-link"
            }
            to="../reports"
          >
            <img src={reporting} />
            <br />
            Reports
          </Link>
          {ryts == 1 ? (
            <Link className="nav-link" to="../products" >
              <img src={products} /><br />
              Manage Products
            </Link>
          ) : ("")}
        </div>
        <ToastContainer />        
      </div>
    );
  }
}
