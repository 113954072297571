import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import CustomerList from "./CustomerList";
import axios from "axios";
import React from "react";
import InvalidAccess from "./InvalidAccess";
import { toast } from "react-toastify";
export default function Customers() {
  if (localStorage.getItem("user") === null) {
    window.location.href = "../";
  }
  
  const [filtered, setFiltered] = useState("");
  const corp_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user");
  const ryts = localStorage.getItem("user_rights");

  const customerInitial = {
    id: 0,
    corp_id: corp_id,
    user_id: user_id,
    company: "",
    name: "",
    name1: "",
    street: "",
    city: "",
    number: "",
    number1: "",
    email: "",
    comments: "",
    module: "customerM",
    product: "quotationB",
    toberemoved: "subMit, reset",
  };
  const [customer, setcustomer] = useState({
    id: 0,
    corp_id: corp_id,
    user_id: user_id,
    company: "",
    lstatus: 1,
    lsource: "",
    name: "",
    name1: "",
    street: "",
    city: "",
    number: "",
    number1: "",
    email: "",
    comments: "",
    module: "customerM",
    product: "quotationB",
    toberemoved: "subMit, reset",
  });
  const [cusList, setCustomerList] = useState([
    {
      id: 0,
      company: "",
      name: "",
      user_id: "",
    },
  ]);
  
  const [getCustomer, setGetCustomer] = useState({
    cusID: 0,
    corp_id: corp_id,
    module: "getCustomer",
    product: "quotationB",
  });

  const getStatus=({
    cusID: 0,
    corp_id: corp_id,
    module: "getStatus",
    product: "quotationB",
  });

  const getSource = ({
    cusID: 0,
    corp_id: corp_id,
    module: "getSource",
    product: "quotationB",
  });

  const [sources, setSources] = useState([
    {
      id:0,
      name:"",
    },
  ]);
  const [status, setStatus] = useState([
    {
      id:0,
      name:"",
    },
  ]);

  async function AxiosCustomers(formData) {
    toast.warning("please wait!");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {
      if(formData.module === "getStatus"){
        //console.log(res.data);
        setStatus(res.data.data);
      }else if(formData.module === "getSource"){
        setSources(res.data.data);
      }else if (formData.module === "customerM") {
        if (res.data.data != undefined) {
          if (formData.id === 0) {
            //const newList = cusList.push({ ...res.data.data[0] });
            const newList = [{ ...res.data.data[0] }, ...cusList];
            setCustomerList(newList);
          } else {
            const newList = cusList.map((cus) => {
              return cus.id === res.data.data[0].id
                ? { ...cus, ...res.data.data[0] }
                : { ...cus };
            });
            setCustomerList(newList);
          }
          toast.success("Error: Managing Customer. possible reason, customer already existing.");
        }
        else{
          toast.warning("no data found");
        }
      } else {
        if (res.data.data != undefined) {
          toast.success("ready to go");
          if (formData.cusID === 0) {
            setCustomerList(res.data.data);
          } else {
            var custs = { ...customer, ...res.data.data[0] };
            setcustomer(custs);
          }
        }else{
          toast.warning("no data found");
        }
      }
    });
  }

  const Callme = (idis) => {
    setGetCustomer({ ...getCustomer, ["cusID"]: idis });
    AxiosCustomers(getCustomer);
  };
  useEffect(() => {
    AxiosCustomers(getCustomer);
    AxiosCustomers(getStatus);
    AxiosCustomers(getSource);
  }, [getCustomer]);
  const handleFilter = (event)=>{
    const { name, value } = event.target;
    setFiltered(value);
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setcustomer({
      ...customer,
      [name]: value,
    });
  };
  const resetForm = () => {
    setcustomer(customerInitial);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (customer.id === 0 || user_id === customer.user_id) {
      AxiosCustomers(customer);
    }
  };
  if (
    localStorage.getItem("user") == 20000 ||
    localStorage.getItem("user") == ""
  ) {
    return <InvalidAccess />;
  }
  return (
    <>
      <Dashboard />
      <div id="content_area">
        <div id="list_section">
          <h2>Customer List</h2>
          <h3><form style={{float:'none'}}>
            <input type="text" onChange={handleFilter} placeholder="filter list by name"  value={filtered}  />
          </form></h3>
          
          <br />
          <div>
            <ul>
              {cusList.filter(function(d){return d.company.toLowerCase().indexOf(filtered.toLowerCase())!=-1;}).map((cus) => {
                if(cus.user_id !== user_id && ryts!=1){ return false; }
                return cus.company != "" ? (
                  <li  key={cus.id}>
                    <CustomerList text={cus.company + " (" + cus.name + ")"} />
                    <button onClick={() => Callme(cus.id)}>View</button>
                  </li>
                ) : (
                  ""
                );
              })}
            </ul>
          </div>
        </div>
        <form method="post" name="customerForm" onSubmit={handleSubmit}>
          <h2>Customer Manager</h2>
          <input type="reset" value="Add new customer" onClick={resetForm} />
          {/* <select
            name="lstatus"
            value={customer.lstatus}
            onChange={handleInputChange}>
            <option>-- lead status --</option>
            {status.map((stat)=>{
              return <option key={stat.id} value={stat.id}>{stat.name}</option>;
            })}
          </select> */}
          <select
            name="lsource"
            value={customer.lsource}
            onChange={handleInputChange}>
              {/* <option>-- lead source --</option> */}
              {sources.map((stat)=>{
                return <option key={stat.id} value={stat.name}>{stat.name}</option>;
            })}
          </select>
          <input
            type="text"
            placeholder="GSTIN i.e. 03AABCA4402ELZ1"
            name="gst"
            className="fieldCompany"
            required
            value={customer.gst}
            onChange={handleInputChange}
          />
          <input
            type="text"
            placeholder="Company Name"
            name="company"
            className="fieldCompany"
            required
            value={customer.company}
            onChange={handleInputChange}
          />
          <input
            type="text"
            placeholder="Customer Name"
            name="name"
            required
            value={customer.name}
            onChange={handleInputChange}
          />
          <input
            type="text"
            placeholder="Secondary Customer Name"
            name="name1"
            value={customer.name1}
            onChange={handleInputChange}
          />
          <textarea
            placeholder="Address"
            name="street"
            onChange={handleInputChange}
            defaultValue={customer.street}
          ></textarea>
          <input
            type="text"
            placeholder="City"
            value={customer.city}
            name="city"
            required
            onChange={handleInputChange}
          />
          <input
            type="tel"
            placeholder="Contact No."
            name="number"
            value={customer.number}
            required
            onChange={handleInputChange}
          />
          <input
            type="tel"
            placeholder="Secondary Contact No."
            name="number1"
            value={customer.number1}
            onChange={handleInputChange}
          />
          <input
            type="email"
            placeholder="Email Id "
            name="email"
            value={customer.email}
            onChange={handleInputChange}
          />
          <textarea
            placeholder="Special Comments..."
            onChange={handleInputChange}
            name="comments"
            defaultValue={customer.comments}
          ></textarea>
          {(customer.user_id === user_id || ryts==1 ) ? (
            <input type="submit" name="subMit" value="Save Customer" />
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  );
}
