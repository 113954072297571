import React, { Component } from 'react'
import { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import { toast } from "react-toastify";
import axios from 'axios';
import { CSVLink } from "react-csv";
import csvImg from "../../images/csv.png";

export default function Lead(){
const corp_id = localStorage.getItem("business_id");
const csvLink = React.createRef();
const user_id = localStorage.getItem("user");
const ryts = localStorage.getItem("user_rights");
const [tmp, settmp] = useState({});
const [status, setStatus] = useState([{}]);
const [orgMembers, setOrgMembers] = useState([{id:0, name:""}]);
const [lead, setLead] = useState([{
  id:0,
  name:"",
  company:"",
  number:"",
  email:"",
  user_id:"",
  lstatus:"",
}]);
const getLeadReport=({
    cusID: 0,
    corp_id: corp_id,
    module: "getLeadReport",
    product: "quotationB",
  });
const getStatus=({
    cusID: 0,
    corp_id: corp_id,
    module: "getStatus",
    product: "quotationB",
  });
const getLeads =({
    cusID: 0,
    corp_id: corp_id,
    user_id: user_id,
    module: "getLeads",
    product: "quotationB",
  });
  const getOrgMem =({
    cusID: 0,
    corp_id: corp_id,
    user_id: user_id,
    module: "getOrgMem",
    product: "quotationB",
  });
  const [LeadHistory, setLeadHistory] =useState({
    cusID: 0,
    corp_id: corp_id,
    user_id: user_id,
    module: "getStatHistory",
    product: "quotationB",
  });
const [classStatusUpdate, setStatusUDT] = useState('displayNone');
const [slctdLD, setslctdLD] = useState({id:0,company:""});
const [orgMemCombo, setorgMemCombo] = useState("all");
const [statHis, setstatHis] = useState([{
  cus_id:0,
  status_id:0,
  date:"",
  user_id:0,
  reason:"",
}]);
const headersCSV = [
  { label: "Company", key: "com" },
  { label: "Status", key: "stat" },
  { label: "Representative", key: "rep" },
  { label: "Date", key: "dt" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
];

const [dataCSV, setdataCSV] = useState([
  { id: "Ahmed", com: "Tomi", name: "ah@smthing.co.com", email: "asdf", phone: "123", stat: "stt", rep:"asdf" },
]);
async function AxiosLead(formData) {
    toast.warning("please wait!");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {
      if(formData.module=="getLeads" && res.data.data!=undefined){
        setLead(res.data.data);
      }else if(formData.module=="getLeadReport" && res.data.data!=undefined){
        toast.warning("Generating File...");
        let dta = res.data.data;
        setdataCSV(dta);
        let crnt= csvLink.current.link;
        setTimeout( ()=>{crnt.click()},4000, crnt) ;
      }else if(formData.module=="getOrgMem" && res.data.data!=undefined){
        setOrgMembers(res.data.data);
      }else if(formData.module=="getStatHistory"){
        if (res.data.data != undefined) {
          setstatHis(res.data.data);
        }else{
          setstatHis([{cus_id:0,status_id:0,date:"",user_id:0,reason:"",}]);
        }
      }else if(formData.module=="udtlStat"){
        const sLead = lead.map((ld)=>{ return (formData.cusID==ld.id) ? {...ld, ['lstatus']: formData.lstatus} : {...ld}});
        setLead(sLead);
        const dcsv = dataCSV.map((ld)=>{ return (formData.cusID==ld.id) ? {...ld, ['stat']: status.filter(stta=>stta.id==formData.lstatus)[0].name} : {...ld}});
        setdataCSV(dcsv);
        setStatusUDT("displayNone");
        toast.success("Status updated successfully...");
      }else{
        setStatus(res.data.data);
        toast.success("Filtering Leads...");
      }      
    });
  }

function changeStatus(idIs){ 
  setLeadHistory({...LeadHistory,['cusID']:idIs});
  const Lstt = (lead.filter((ld) => ld.id==idIs ));
  setslctdLD(Lstt[0]);
  setTimeout(()=>{
    setStatusUDT("udtStatus");
  },1000);
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
const callHistory = ()=>{
  AxiosLead(LeadHistory);
  setcloseResponse("response");
}
  
const [udtStat, setudtStat] = useState({
    cusID: 0,
    user_id: user_id,
    lstatus: 0,
    reason: "",
    corp_id: corp_id,
    module: "udtlStat",
    product: "quotationB",
  });
const manageStat = (e) =>{

  setslctdLD({...slctdLD,[e.target.name]:e.target.value});
  setudtStat({...udtStat,[e.target.name]:e.target.value, ['cusID']:slctdLD.id });

};
function handleStatus(e){
  e.preventDefault();
  setudtStat({...udtStat, ['cusID']:slctdLD.id});
  setTimeout(()=>{
    AxiosLead(udtStat)}, 2000
  );
}
const [closeResponse, setcloseResponse] = useState('displayNone');
const [comFilter, setComFilter] = useState('');
const handleFilter = (e) =>{
  setComFilter(e.target.value);
}
const udtmemcomb = (e)=>{
  setorgMemCombo(e.target.value);
}
useEffect(() => {
    AxiosLead(getStatus);
    AxiosLead(getOrgMem);
    AxiosLead(getLeads);
  }, []);
    return (
      <div >
        <Dashboard />
        <div id='content_area'>
          
          Representatives:  <select onChange={udtmemcomb} name='orgMemCombo'>
              <option value={'all'}>All</option>
              {orgMembers.map((orgM)=>{ 
                return <option value={orgM.id}>{orgM.name}</option> 
                })
              }</select>
              
          <div id="logout">Search: <input type='text' value={comFilter} name='searchcom' onChange={handleFilter} /><br />
          <span className="displayNONE">
          {(ryts=="1")? <img onClick={()=>{AxiosLead(getLeadReport)}} src={csvImg} /> : "" }
          <CSVLink  filename={'leads.csv'} data={dataCSV} headers={headersCSV} target="_blank" ref={csvLink}>
          </CSVLink>
          </span>
          </div>
          <div className='clear'></div>
            {/* msgs */}
            <div className={classStatusUpdate}>
              <p id='closeIt' onClick={()=>{setStatusUDT("displayNone")}}>X</p>
          <div>
              <form method='post' onSubmit={handleStatus}>
                <input type='hidden' name='id' value={slctdLD.id} />
                <h3>CHANGE STATUS ({slctdLD.company})</h3>
                <select onChange={manageStat} name='lstatus' required value={slctdLD.lstatus}>
                  {status.map((stat)=>{ 
                    return <option key={stat.id} value={stat.id}>{stat.name}</option>
                  })}
                </select>
                <textarea name='reason' required onChange={manageStat} placeholder='reasons of status update'></textarea>
                <input type='submit' name='save' value={'Save'} />
              </form>
              <button onClick={callHistory} className='btn btn-secondary btn-sm'>History</button>

              <div className={closeResponse}>
                <span id='closeIt'  onClick={()=>{setcloseResponse("displayNone");}}>close</span>
                      {statHis.map((his)=>{
                        const nm = status.filter(stt=>stt.id===his.status_id);
                        const mem = orgMembers.filter(orgM=>orgM.id==his.user_id);
                        //{nm.name} by {mem}
                        return (nm[0]) ?  <p key={his.id}>{nm[0].name}  on  {his.date} by {mem[0].name}<br /><hr />{his.reason}</p> : "";
                        
                      })}
                </div>
          </div>
        </div>

            {/* under followup */}
            
            {status.map((stat)=>{ 
              var noleads = 0;
              return <div id='lead_container' key={stat.id} className='by_two'>
                <h5>{stat.name}</h5>
                <table id='leadTable'>
                  <thead><tr><th>Company</th><th>Stt. DT.</th><th>Customer</th><th>Phone</th><th>Rep.</th></tr></thead><tbody>
                {lead.map((ld)=>{ 
                  var usrNm = "";
                  const usrnms = orgMembers.filter(orgM=>orgM.id==ld.user_id);
                  if(usrnms[0]!=undefined){
                    usrNm = usrnms[0].name;
                  }
                  var data2csv = {};
                  var tmp = {};

                  if(ld.lstatus==stat.id){
                    if(orgMemCombo=="all"){
                      if(comFilter!=""){
                        if(ld.company.toLowerCase().indexOf(comFilter.toLowerCase())>-1){ noleads++;                          
                          return <tr key={ld.id} onClick={()=>{changeStatus(ld.id)}}><td><b>{ld.company}</b></td><td>{ld.lstat_dt}</td><td>{ld.name}</td><td>{ld.number}</td><td>{usrNm}</td></tr>;  
                        }
                      }else{ noleads++;
                        return <tr key={ld.id} onClick={()=>{changeStatus(ld.id)}}><td><b>{ld.company}</b></td><td>{ld.lstat_dt}</td><td>{ld.name}</td><td>{ld.number}</td><td>{usrNm}</td></tr>;
                      }
                    }else if(ld.user_id==orgMemCombo){
                      if(comFilter!=""){
                        if(ld.company.toLowerCase().indexOf(comFilter.toLowerCase())>-1){ noleads++;
                        
                          return <tr key={ld.id} onClick={()=>{changeStatus(ld.id)}}><td><b>{ld.company}</b></td><td>{ld.lstat_dt}</td><td>{ld.name}</td><td>{ld.number}</td><td>{usrNm}</td></tr>;  
                        }
                      }else{ noleads++;
                        
                        return <tr key={ld.id} onClick={()=>{changeStatus(ld.id)}}><td><b>{ld.company}</b></td><td>{ld.lstat_dt}</td><td>{ld.name}</td><td>{ld.number}</td><td>{usrNm}</td></tr>;
                      }
                    }
                  }
                 })}
                 </tbody>
                 </table>
                 <div id='leadcount'>No. <b>{noleads}</b></div>
                </div>;
              })}
            
        </div>
      </div>
    )
}
