import { useState } from "react";
import Dashboard from "./Dashboard";
//import NotificationManager from "react-notifications";
import axios from "axios";
import React from "react";
import InvalidAccess from "./InvalidAccess";
import { toast } from 'react-toastify';
export default function Reports() {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
const ryts = localStorage.getItem("user_rights");
  const [reportfor, setReportFor] = useState({
    to: "",
    from: "",
    user_id: localStorage.getItem("user"),
    corp_id: localStorage.getItem("business_id"),
    module: "forReport",
    product: "quotationB",
  });
  const [reportData, setReportData] = useState([
    {
      id: 0,
      name: "",
      status: 0,
      customer: "",
      comment: "",
      date_entered: "",
      user: "",
      total: "",
    },
  ]);
  const generatePDF = (pdfID) => {
    localStorage.setItem("generatePDF", pdfID);
    window.open("../pdf");
  };
  async function AxiosReport(formData) {
    //NotificationManager.info("please wait...");
    JSON.stringify(formData);
    axios({
      method: "post",
      url: "https://www.bestwebdealers.com/reactManager/",
      data: formData,
    }).then((res) => {
      if(res.data!=null){
      if (formData.module === "forReport") {
        setReportData(res.data.data);
      }}else{
        toast.warning("no result found!");
      }
      if(formData.module === "manageQStatus"){
        if(res.data.includes("record upd")){
          const newdata = reportData.map((rData)=>{
            return (rData.id==formData.id) ? {...rData,['status']:2} : {...rData} ;
          });
          setReportData(newdata);
        }
      }
    });
  }
  
  const handleOnchange = (event) => {
    const { name, value } = event.target;
    setReportFor({ ...reportfor, [name]: value });
  };

  const corp_id = localStorage.getItem("business_id");

  const convertPI = (event) =>{
    if(window.confirm("are you sure you want to generate P.I.?")){
    const udtVal = (event.target.checked) ? 2 : 1 ;
    const quoteInitial = {
      id: event.target.value,
      status:udtVal,
      corp_id: corp_id,
      module: "manageQStatus",
      product: "quotationB",
    };
    AxiosReport(quoteInitial);
    // toast.info();
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    AxiosReport(reportfor);
  };
  if (
    localStorage.getItem("user") === 20000 ||
    localStorage.getItem("user") === ""
  ) {
    return <InvalidAccess />;
  }
  return (
    <>
      <Dashboard />
      <div id="content_area">
        <h2>Reporting Sales</h2>
        <form onSubmit={handleSubmit} method="post">
          <input
            type="date"
            required
            name="from"
            onChange={handleOnchange}
            max={year + "-" + month + "-" + date}
            style={{ width: "135px", float: "left" }}
          />
          <input
            type="date"
            required
            name="to"
            onChange={handleOnchange}
            max={year + "-" + month + "-" + date}
            style={{ width: "135px", float: "left" }}
          />
          <input
            type="submit"
            name="generate"
            value={"Generate"}
            style={{ width: "135px", float: "left", textAlign: "center" }}
          />
        </form>
        <table className="quoteTable">
          <thead>
          <tr>
            <th>Customer</th>
            <th>Quote Name</th>
            <th>Representative</th>
            <th>Taxable</th>
            <th>P.I.</th>            
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {reportData.map((quote) => {

            const stt = (quote.status==2) ? true : false ;
            
            return quote.name === "" || (ryts==3 && !stt ) ? (
              ""
            ) : (
              <tr key={quote.id}>
                <td>{quote.customer}</td>
                <td>{quote.name}<small><br />({quote.date_entered})</small></td>
                <td>{quote.user}</td>
                <td>{quote.total}</td>
                <td>
                  <input type="checkbox" name="status" value={quote.id} onChange={convertPI} checked={stt} disabled={stt} />
                </td>
                <td>
                  <button
                    onClick={() => {
                      generatePDF(quote.id);
                    }}
                  >
                    generate pdf
                  </button>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </>
  );
}
